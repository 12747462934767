<template>
  <b-card :title="$t('cash_registers')">
    <b-row>
      <b-col sm="12"  class="text-right">
        <b-button size="sm" variant="success" @click="openModal"><fa icon="plus" /></b-button>
      </b-col>
    </b-row>
    <table class="table">
      <thead>
      <tr>
        <th>Назва каси</th>
        <th class="text-center">Баланс</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item.id">
        <td>{{ item.name }}</td>
        <td class="text-center">0</td>
        <td class="text-right">
          <b-btn-group>
            <b-button size="sm" @click="openInfo(item.id)" variant="warning"><fa icon="pencil-alt"/></b-button>
            <b-button size="sm" @click="deleteCashRegister(item.id)" variant="danger"><fa icon="times" /></b-button>
          </b-btn-group>
        </td>
      </tr>
      </tbody>
    </table>
    <b-modal id="cash-modal" size="sm" hide-footer>
      <div class="form-group">
        <label for="name">{{ $t('name') }}</label>
        <input type="text" v-model="form.name" required class="form-control form-control-sm">
      </div>
      <b-button size="sm" variant="primary" @click="save">{{ $t('save') }}</b-button>
    </b-modal>
  </b-card>
</template>
<script>
import axios from 'axios'
import Form from 'vform'
export default {
  data: () => ({
    items: null,
    form: new Form({
      id: null,
      name: ''
    })
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'cash-register').then(resp => {
        if (resp.data) {
          this.items = resp.data
        }
      })
    },
    save () {
      if (this.form.id) {
        this.edit()
      } else {
        this.add()
      }
    },
    async add () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.post(apiUrl + 'cash-register').then(resp => {
        if (resp.data.status) {
          this.getData()
          this.form.reset()
          this.$bvModal.hide('cash-modal')
        }
      })
    },
    async edit () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.put(apiUrl + 'cash-register/' + this.form.id).then(resp => {
        if (resp.data.status) {
          this.getData()
          this.form.reset()
          this.$bvModal.hide('cash-modal')
        }
      })
    },
    async deleteCashRegister (id) {
      if (window.confirm(this.$t('want_to_delete'))) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.delete(apiUrl + 'cash-register/' + id).then(resp => {
          if (resp.data.status) {
            this.getData()
          }
        })
      }
    },
    openInfo (id) {
      const item = this.items.find(i => i.id === id)
      if (typeof item !== 'undefined') {
        this.form.fill(item)
        this.$bvModal.show('cash-modal')
      }
    },
    openModal () {
      this.form.reset()
      this.$bvModal.show('cash-modal')
    }
  }
}
</script>
